<template>
    <div class="user">

        <h5header :name="$t('login.desc9')" :right="false" />
        <div class="user-con">
            <div class="user-info flex">
                <!-- <img src="../../public/images/new/userimg.png" alt="" class="logo" /> -->
                <div class="logo">
                    <star-img :sphereImg="sphereImg" :starWidth="80" :starHeight="80" />
                </div>
                <div class="info" v-if="islogin">
                    <div class="info-name">{{ info.email }}</div>
                    <div class="info-uid flex">
                        <div>UID：</div>
                        <p class="flex">{{ info.userId }} <img src="../../public/images/new/copy1.png" alt=""
                                @click="copy($event, info.userId)" /></p>
                    </div>
                    <div class="info-uid flex">
                        <div>{{ $t('sign.desc70') }}</div>
                        <p class="flex">{{ currentStarLevelName }}
                            <!-- <img src="../../public/images/new/tips.png" alt="" /> -->
                        </p>
                    </div>
                </div>
                <div class="info" v-else>
                    <div class="info-btn flex">
                        <div @click="$router.push('/login')">{{ $t('sign.desc1') }}</div>
                        <div @click="$router.push('/register')">{{ $t('sign.desc2') }}</div>
                    </div>

                </div>
            </div>
            <div class="help-name" v-if="islogin">{{ $t('market.desc126') }}</div>
            <div class="user-help flex" v-if="islogin">
                <div class="list-info " @click="$router.push('/planet')">

                    <img src="../../public/images/new/usericon13.png" alt="" class="img1" />
                    <div>{{ $t('new.desc4') }}</div>
                </div>
                <div class="list-info " @click="$router.push('/manage')">
                    <img src="../../public/images/new/usericon14.png" alt="" class="img1" />
                    <div>{{ $t('new.desc3') }}</div>
                </div>
                <div class="list-info " @click="handlerLink(1)">
                    <img src="../../public/images/new/usericon1.png" alt="" class="img1" />
                    <div>{{ $t('sign.desc41') }}</div>
                </div>
                <div class="list-info " @click="handlerLink(2)">
                    <img src="../../public/images/new/usericon2.png" alt="" class="img1" />
                    <div>{{ $t('home.desc7') }}</div>
                </div>
                <div class="list-info " @click="handlerLink(3)">
                    <img src="../../public/images/new/usericon3.png" alt="" class="img1" />
                    <div>{{ $t('home.desc8') }}</div>
                </div>
                <div class="list-info " @click="$router.push('/invite')">
                    <img src="../../public/images/new/usericon16.png" alt="" class="img1" />
                    <div>{{ $t('home.desc6') }}</div>
                </div>
                <div class="list-info " @click="$router.push('/reward')">
                    <img src="../../public/images/reward/usericon18.png" alt="" class="img1" />
                    <div>{{ $t('blind.desc1') }}</div>
                </div>
                <div class="list-info " @click="$router.push('/lockRelease')">
                    <img src="../../public/images/daot/usericon-daot.png" alt="" class="img1" />
                    <div>{{ $t('lockrelease.desc20') }}</div>
                </div>
                <div class="list-info " @click="$router.push('/vote')">
                    <img src="../../public/images/vote/icon2.png" alt="" class="img1" />
                    <div>{{ $t('vote.desc41') }}</div>
                </div>
                <div class="list-info" @click="$router.push('/node')">
                    <img src="../../public/images/daot/usericon99.svg" alt="" class="img1" />
                    <div>{{ $t('footer.desc22') }}</div>
                </div>
            </div>
            <div class="help-name">{{ $t('home.desc9') }}</div>
            <div class="user-help flex">
                <!-- <div class="list-info " @click="$router.push('/blind')">
                    <img src="../../public/images/new/usericon17.png" alt="" class="img1" />
                    <div>{{ $t('blind.desc1') }}</div>
                </div> -->
                <div class="list-info " @click="handlerPath('HELP_CENTER')">
                    <img src="../../public/images/new/usericon4.png" alt="" class="img1" />
                    <div>{{ $t('home.desc9') }}</div>
                </div>
                <div class="list-info " @click="handlerPath('CUSTOMER_SERVICE_LINK')">
                    <img src="../../public/images/new/usericon5.png" alt="" class="img1" />
                    <div>{{ $t('login.desc11') }}</div>
                </div>
                <div class="list-info " @click="handlerPath('ANNOUNCEMENT')">
                    <img src="../../public/images/new/usericon6.png" alt="" class="img1" />
                    <div>{{ $t('sign.desc51') }}</div>
                </div>
                <div class="list-info " @click="$router.push('/brand')">
                    <img src="../../public/images/new/usericon17.png" alt="" class="img1" />
                    <div>{{ $t('brand.desc83') }}</div>
                </div>
                <div class="list-info " @click="dialogLang = true">
                    <img src="../../public/images/new/usericon15.png" alt="" class="img1" />
                    <div>{{ $t('market.desc127') }}</div>
                </div>
            </div>
            <div class="help-name">{{ $t('market.desc125') }}</div>
            <div class="user-list">
                <div class="list-info flex" @click="handlerPath1('https://t.me/daoversal')">
                    <img src="../../public/images/new/usericon8.png" alt="" class="img1" />
                    <div>Telegram</div>
                    <img src="../../public/images/new/usericon7.png" alt="" class="img2" />
                </div>
                <div class="list-info flex" @click="handlerPath1('https://twitter.com/daoversal')">
                    <img src="../../public/images/new/usericon9.png" alt="" class="img1" />
                    <div>Twitter</div>
                    <img src="../../public/images/new/usericon7.png" alt="" class="img2" />
                </div>
                <div class="list-info flex" @click="handlerPath1('https://www.facebook.com/daoversal/')">
                    <img src="../../public/images/new/usericon10.png" alt="" class="img1" />
                    <div>Facebook</div>
                    <img src="../../public/images/new/usericon7.png" alt="" class="img2" />
                </div>
                <!-- <div class="list-info flex">
                  <img src="../../public/images/new/usericon11.png" alt="" class="img1" />
                  <div>LinkedIn</div>
                  <img src="../../public/images/new/usericon7.png" alt="" class="img2" />
              </div> -->
                <div class="list-info flex" @click="handlerPath1('https://www.youtube.com/@Daoversal')">
                    <img src="../../public/images/new/usericon12.png" alt="" class="img1" />
                    <div>Youtube</div>
                    <img src="../../public/images/new/usericon7.png" alt="" class="img2" />
                </div>
            </div>
            <div class="user-out flexcenter" @click="dialogBackset = true" v-if="islogin">{{ $t('home.desc10') }}</div>
        </div>
        <!-- <div id="footer">
          <glFooter :act="4" />
      </div> -->
        <el-dialog title="提示" v-model="dialogBackset" top="30vh" width="30%" :append-to-body="true" class="modalinfo">
            <div class="modalinfo-con">
                <div class="modalinfo-con-title" style="margin-bottom: 30px;">
                    {{ $t('home.desc44') }}

                </div>
                <div class="modalinfo-con-btn flex">
                    <div class="flexcenter" @click="dialogBackset = false">{{ $t('home.desc45') }}</div>
                    <div class="flexcenter" @click="handlerLogout">{{ $t('home.desc46') }}</div>
                </div>
            </div>
        </el-dialog>
        <van-popup v-model:show="dialogLang" position="bottom" round>
            <div class="lang-info">
                <div class="lang-title">{{ $t('sign.desc36') }} <img src="../../public/images/closemodal.png" alt=""
                        @click="dialogLang = false" /></div>
                <div class="lang-item flex" v-for="item in langList" :key="item.id" @click="handleCommand(item.code)">
                    <div>{{ item.name }}</div>
                    <img :src="item.code == lang ? require('../../public/images/new/checkimg.png') : require('../../public/images/new/checkimg1.png')"
                        alt="" />
                </div>
            </div>
        </van-popup>
        <!-- 谷歌密码 -->
        <el-dialog v-model="showGoogle" :append-to-body="true">
            <div class="modal-con1">
                <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showGoogle = false" />
                <div class="modal-title">{{ $t("home.desc64") }}</div>
                <div class="modal-desc">{{ $t("home.desc65") }}</div>
                <div class="modal-btn flex">
                    <div class="flexcenter" @click="showGoogle = false">
                        {{ $t("node.desc47") }}
                    </div>
                    <div class="flexcenter" @click="handlerSubmit">
                        {{ $t("node.desc48") }}
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import h5header from '@/componets/h5header.vue';
import starImg from '@/componets/starImg.vue'
import glFooter from '@/componets/footer.vue'
import clipboard from '@/utils/libs/clipboard'
export default {
    inject: ['reload'],
    components: {
        h5header,
        starImg,
        glFooter
    },
    data() {
        return {
            dialogBackset: false,
            info: {},
            currentStarLevelName: '',
            sphereImg: require('../../public/images/new/userimg.png'),
            langList: [],
            dialogLang: false,
            lang: '',
            islogin: false,
            showGoogle: false,
            transferShow:false,
        }
    },
    mounted() {
        this.lang = localStorage.getItem('language');
        this.langList = JSON.parse(localStorage.getItem('lang'))
        if (localStorage.getItem('token') != null) {
            this.islogin = true;
            this.$post(this.URL.invite.info, {}).then(res => {
                if (res.code == 0) {
                    this.currentStarLevelName = res.data.currentLevelName;
                } else {
                    this.$message(res.message)
                }
            })
            this.$post(this.URL.member.info, {
            }).then(res => {
                if (res.code == 0) {
                    this.info = res.data;
                }
            })
        };
        this.transferShow = this.$store.state.transferShow;
    },
    methods: {
        copy(e, text) {
            text = text.toString()
            clipboard(e, text)
            this.$message.success(this.$t('planet.desc61'))
        },
        handlerSubmit() {
            this.$router.push(`/user/info?id=4`)
        },
        handlerLogout() {
            this.$message.success(this.$t('home.desc47'))
            this.$store.commit('SET_TOKEN', '')
            this.$store.commit('SET_USERDATA', {})
            this.$store.commit('SET_LOGIN', false)
            localStorage.removeItem('vuex');
            localStorage.removeItem('token');
            localStorage.removeItem('address');
            this.dialogBackset = false;
            this.$router.push('/stake');
            this.reload()
            // setTimeout(() => {
            //     location.reload();
            // }, 1000);

        },
        handlerLink(i) {
            if(i==99){
                if(!this.$store.state.isHvUser){
                    this.$message.success(this.$t('transferAccount.desc21'));
                    return
                }
            }
            this.$router.push(`/user/info?id=${i}`)
        },
        handlerPath(name) {
            let info = this.$store.state.h5Url.find(item => item.tag == name);

            if (info != undefined) {

                window.open(info.url)
            }
        },
        handlerPath1(url) {
            window.open(url)
        },
        handleCommand(val) {

            localStorage.setItem('language', val);
            this.dialogBackset = false;
            setTimeout(() => {
                location.reload();
            }, 600);
        },
    }
}
</script>

<style lang="less" scoped>
.user-con {
    padding: 44px 16px 90px;

    .user-info {
        .logo {
            width: 68px;
            height: 68px;
            margin-right: 12px;
            border-radius: 50%;
            object-fit: cover;
        }

        .info {
            flex: 1;

            .info-btn {
                margin-top: 16px;

                div {
                    flex: 0 0 96px;
                    height: 36px;
                    background: url('../../public/images/h5btnbg2.png') center no-repeat;
                    background-size: 100% 100%;
                    font-size: 14px;
                    color: #000;
                    line-height: 34px;
                    text-align: center;

                    &:last-child {
                        margin-left: 12px;
                        background: url('../../public/images/h5btnbg1.png') center no-repeat;
                        background-size: 100% 100%;
                        color: #fff;
                    }
                }
            }

            .info-name {
                padding-bottom: 4px;
                font-size: 18px;
                color: #FFFFFF;
                line-height: 18px;
            }

            .info-uid {
                margin-top: 8px;
                font-size: 13px;
                color: #909090;
                line-height: 13px;

                p {
                    color: #fff;

                    img {
                        width: 13px;
                        height: 13px;
                        margin-left: 4px;
                    }
                }
            }
        }
    }

    .user-balance {
        padding: 16px;
        margin: 20px 0 12px;
        border-radius: 8px;
        background: #181818;

        .balance-name {
            font-size: 14px;
            color: #fff;
            line-height: 14px;

            img {
                width: 12px;
                height: 12px;
                margin: 1px 6px 0 0;
            }
        }

        .balance-price {
            margin-top: 12px;
            font-size: 24px;
            color: #C9FA5B;
        }
    }

    .help-name {
        padding: 20px 0 12px;
        font-size: 14px;
        color: #FFFFFF;
    }

    .user-help {
        flex-wrap: wrap;
        padding: 16px 0;
        background: #181818;
        border-radius: 8px;

        .list-info {
            flex: 0 0 25%;
            margin-top: 16px;
            text-align: center;

            &:nth-child(-n + 4) {
                margin-top: 0;
            }

            img {
                width: 28px;
                height: 28px;
            }

            div {
                margin-top: 8px;
                font-size: 12px;
                color: #FFFFFF;
            }
        }
    }

    .user-list {
        padding: 0 14px;
        border-radius: 8px;
        background: #181818;
        margin-bottom: 12px;

        .list-info {
            position: relative;
            padding: 16px 0;

            &::after {
                position: absolute;
                content: '';
                left: 32px;
                bottom: 0;
                width: calc(100% - 8px);
                height: 1px;
                background: rgba(255, 255, 255, 0.06);
            }

            .img1 {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }

            div {
                flex: 1;
                line-height: 24px;
                font-size: 14px;
                color: #FFFFFF;
            }

            .img2 {
                width: 5px;
                height: 8px;
                margin: 8px 0 0 8px;
            }
        }
    }

    .user-out {
        height: 44px;
        border-radius: 8px;
        background: #181818;
        font-size: 15px;
        color: #EB4343;
    }

}

.modal-con1 {
    width: calc(100vw - 8px);
    padding: 16px;

    .close {
        position: absolute;
        top: 32px;
        right: 32px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .modal-title {
        margin-bottom: 32px;
        font-size: 20px;
        color: #ffffff;
    }

    .modal-desc {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.8);
    }


    .modal-btn {
        margin-top: 32px;
        justify-content: space-between;

        div {
            flex: 0 0 48%;
            height: 44px;
            background: url("../../public/images/new/btnbg14.png") center no-repeat;
            background-size: 100% 100%;
            font-size: 18px;
            color: #ffffff;
            cursor: pointer;

            &:last-child {
                background: url("../../public/images/new/btnbg15.png") center no-repeat;
                background-size: 100% 100%;
                color: #000000;
            }
        }
    }
}

.lang-info {
    padding: 32px 16px;
    background: #181818;

    .lang-title {
        position: relative;
        font-size: 18px;
        color: #fff;
        text-align: center;
        margin-bottom: 20px;

        img {
            position: absolute;
            top: 4px;
            right: 0;
            width: 14px;
            height: 14px;
            cursor: pointer;
        }
    }

    .lang-item {
        padding: 10px 0;

        &:last-child {
            margin-bottom: 0;
        }

        div {
            flex: 1;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 44px;
        }

        img {
            width: 16px;
            height: 16px;
            margin: 14px 0 0 0;

        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}</style>